// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PricingForm_pricingFormBtn__1jRj- {\n  display: inline-block;\n  width: 100%;\n  height: 3.125rem;\n  border: 1px solid #0075cc;\n  border-radius: 99px !important;\n  background-color: #0075cc !important;\n  color: #fff !important;\n  font: 700 0.75rem/1.75rem \"Poppins\", sans-serif;\n  cursor: pointer;\n  transition: all 0.2s;\n}\r\n", "",{"version":3,"sources":["webpack://src/components/Pricing/PricingForm.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,WAAW;EACX,gBAAgB;EAChB,yBAAyB;EACzB,8BAA8B;EAC9B,oCAAoC;EACpC,sBAAsB;EACtB,+CAA+C;EAC/C,eAAe;EACf,oBAAoB;AACtB","sourcesContent":[".pricingFormBtn {\r\n  display: inline-block;\r\n  width: 100%;\r\n  height: 3.125rem;\r\n  border: 1px solid #0075cc;\r\n  border-radius: 99px !important;\r\n  background-color: #0075cc !important;\r\n  color: #fff !important;\r\n  font: 700 0.75rem/1.75rem \"Poppins\", sans-serif;\r\n  cursor: pointer;\r\n  transition: all 0.2s;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pricingFormBtn": "PricingForm_pricingFormBtn__1jRj-"
};
export default ___CSS_LOADER_EXPORT___;
