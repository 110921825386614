// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HomeTestimonials_homeTestimonialsLeft__TyrmA,\r\n.HomeTestimonials_homeTestimonialsRight__GroM4 {\n  background-color: #186fc5;\n  color: #fff;\n  font-size: 1rem;\n  padding: 0.5rem 0.8rem;\n  border-radius: 50%;\n  cursor: pointer;\n}\r\n", "",{"version":3,"sources":["webpack://src/components/Home/HomeTestimonials.module.css"],"names":[],"mappings":"AAAA;;EAEE,yBAAyB;EACzB,WAAW;EACX,eAAe;EACf,sBAAsB;EACtB,kBAAkB;EAClB,eAAe;AACjB","sourcesContent":[".homeTestimonialsLeft,\r\n.homeTestimonialsRight {\r\n  background-color: #186fc5;\r\n  color: #fff;\r\n  font-size: 1rem;\r\n  padding: 0.5rem 0.8rem;\r\n  border-radius: 50%;\r\n  cursor: pointer;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"homeTestimonialsLeft": "HomeTestimonials_homeTestimonialsLeft__TyrmA",
	"homeTestimonialsRight": "HomeTestimonials_homeTestimonialsRight__GroM4"
};
export default ___CSS_LOADER_EXPORT___;
