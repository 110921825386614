export const collaboratorListItems = [
    'Unlimited Users',
    'Free Hosting upto 40GB',
    'Upto 2 courses',
    'Unlimited Public & Private Communities',
    'One Administrator',
    'Default Branding',
    'Custom Institute Analytics',
    'Complete Student Analytics',
    'File Sharing (Documents, Presentations & more)',
    'Flashcards',
    'Self hosted Video Sharing',
    'Event Management, Ideas & Feedback Portal',
    'Gamified Learning ',
    'Built in Hashtagging system',
    'iOS and Android App included',
    'Email support',
    'Auto sign up option',
    'One-on-One events'
]

export const createrListItems = [
    'Unlimited Users',
    'Free Hosting upto 1.5TB',
    'Upto 8 courses',
    'Unlimited Public & Private Communities',
    'Upto 5 administrators',
    'Custom Branding- Webpage & App',
    'Custom Institute Analytics',
    'Complete Student Analytics',
    'File Sharing (Documents, Presentations & more)',
    'Flashcards',
    'Self hosted Video Sharing',
    'Event Management, Ideas & Feedback Portal',
    'Gamified Learning (with custom badge creation)',
    'Built in Hashtagging system',
    'iOS and Android App included',
    'Premium Email Support ',
    'Auto sign up option',
    'One-on-One events',
    'Course Builder',
    ' Unlimited Modules Drip Content with Scheduling',
    'Assignments and Homework',
    'Timed Quizzes and Tests',
    'Auto Grading for quizzes and tests',
    'Live classes',
    'Integration with Zoom ',
    'Native Video Upload',
    'Basic Content Protection',
    'Lead Generation API',
    'Upto 5,000 marketing emails',
    'Native Email Editor',
    '200 Email Validation Credits',
    'Marketing Analytics',
    'Upto 3 Access Types',
    'Webhooks'
]

export const ezCreatorList = [
    'Unlimited Users',
    'Unlimited Hosting',
    'Unlimited Courses',
    'Unlimited Public & Private Communities',
    'Upto 25 administrators',
    'Custom Branding- Webpage & App',
    'Custom Institute Analytics',
    'Complete Student Analytics',
    'File Sharing (Documents, Presentations & more)',
    'Flashcards',
    'Self hosted Video Sharing',
    'Event Management, Ideas & Feedback Portal',
    'Gamified Learning (with custom badge creation)',
    'Built-in Hashtagging System',
    'iOS and Android App included',
    'Premium Email and Call Support',
    'Auto sign up option',
    'One-on-One events',
    'Course Builder',
    'Unlimited Modules Drip Content with Scheduling',
    'Assignments and Homework',
    'Timed Quizzes and Tests',
    'Auto Grading for quizzes and tests',
    'Live classes',
    'Integration with Zoom ',
    'Native Video Upload',
    'Basic Content Protection',
    'Lead Generation API',
    'Upto 20,000 marketing emails',
    'Native Email Editor',
    'Unlimited Email Validation Credits',
    'Marketing Analytics',
    'Upto 3 Access Types',
    'Webhooks',
    'Social Media Content Scheduler',
    'Connect Facebook group, page and instagram business',
    'Complete Done-For-You Onboarding',
    'Early Access to New Features',
    'Dedicated Account Manager',
    'Dedicated Designer',
    'Dedicated Web Developer',
    'Upto 5 web pages Included ',
    'Upto 2 landing pages for marketing campaign per month',
    'Upto 4 edits of web designs and campaigns per month',
    'Web design includes UI design + Custom Development + Hosting + Payment Gateway'
]
