export const listItems1 = [
    'Unlimited Public & Private Communities',
    'Bulk user creation',
    'Custom Branding',
    'Comprehensive Institute Analytics',
    'Complete Student Analytics',
    'File Sharing (Documents, Presentations, Videos & more)',
    'Anonymous sharing for students',
    'Flashcards',
    'Self hosted Video Sharing',
    'Native Video Upload',
    'Live Classes',
    'Built-in Zoom Integration',
    'Event Management Portal',
    'Feedback Portal',
]

export const listItems2 = [
    'Ideas & Suggestions Portal',
    'Knowledge base for every course',
    'Gamified Learning (with custom badge creation)',
    'Built-in Hashtagging System',
    'iOS and Android App Included',
    'Custom sign up page with auto sign-up',
    'Advanced Course Builder',
    ' Unlimited Modules and Sub-Modules',
    'Assignments and Homework',
    'Timed Quizzes and Tests',
    'Auto Grading for quizzes and tests',
    'One-on-one events',
    'Lead Generation API',
    'Native Email Editor',
]
export const listItems3 = [

    'Sales Analytics with Stripe Integration',
    'Marketing Analytics',
    'Google Analytics Integration',
    'Premium Email Support ',
    'Webhooks (Coming Soon)',
    'Drip Content (Coming soon)',
    'Zapier and Pabbly (Coming soon)',
    'Multiple institute workspace (coming soon)',
    'Custom Mind Maps (Coming soon)',
    'Question Bank with tags and categories (Coming soon)',
    'Multilingual (Coming Soon)',
    'Custom help section for your institute (Coming Soon)',
    'Custom Domain (Coming Soon)'
]


export const addOns = [
    'Add 50 GB per month storage for $19/mo',
    'Add additional 5000 emails/mo for $19/mo',
    'Get both add ons for $31/mo'
]
export const tier1 = [
    '1000 Students',
    '3 instructors',
    '1 Admin',
    '100 GB Storage',
    '6 Courses',
    'Unlimited User Access Categories',
    'White Labelling',
    '3000 Marketing emails/ mo',
    'All Future Creator Plan Features'
]
export const tier2 = [
    '3000 Students',
    '7 instructors',
    '1 Admin',
    '300 GB Storage',
    'Unlimited Courses',
    'Unlimited User Access Categories',
    'White Labelling',
    '6000 Marketing emails/ mo',
    'All Future Creator Plan Features'
]
export const tier3 = [
    'Unlimited Students',
    'Unlimited instructors',
    '1 Admin',
    '500 GB Storage',
    'Unlimited Courses',
    'Unlimited User Access Categories',
    'White Labelling',
    '10000 Marketing emails/ mo',
    'Social Media Content Scheduler',
    'All Future Creator Plan Features'
]

export const agency = [
    '3000 Students',
    'Upto 7 instructors',
    '1 Admin',
    '300 GB Storage',
    'Unlimited Courses',
    'Unlimited User Access Categories',
    'White Labelling',
    '6000 Marketing emails/ mo',
    'All Future Creator Plan Features'
]