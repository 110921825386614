import React from 'react'
import { Link } from 'react-router-dom'
function HomeVideo() {
    return (
        <div>
            <div className="lg:grid grid-cols-2 lg:items-center lg:justify-around p-5 mt-10 lg:mt-20">
                <div>
                    <h2 className='lg:w-4/5 text-3xl font-bold'
                        style={{ color: '#2275C7' }}
                    >
                        Save thousands of $$$ per year on your online course.
                    </h2>

                    <h2 className='lg:w-4/5 text-xl my-4'
                        style={{ color: '#2A3158' }}
                    >
                        EZ Colab provides a mini team consisting of a <span style={{ color: '#2275C7' }}> designer, a developer and a manager </span> working to help you succeed with your online course
                    </h2>

                    <h2 className='lg:w-4/5 text-xl'
                        style={{ color: '#2A3158' }}
                    >
                        We build custom websites and landing pages that are both beautiful and converting, helping you increase your online course income
                    </h2>
                </div>

                <div className='mt-10 lg:mt-0 grid place-items-center '>
                    <div style={{ left: 0, width: '100%', height: 0, position: 'relative', paddingBottom: '56.25%' }}>
                        <figure style={{ left: 0, width: '100%', height: 0, position: 'relative', paddingBottom: '56.25%', marginBlockEnd: 0, marginBlockStart: 0, marginInlineStart: 0, marginInlineEnd: 0 }}>
                            <iframe title='home-video' src="https://api.vadoo.tv/iframe_test?id=BhxSTDFz16BCXnNA4BxCRvYhcykoeCMQ" scrolling="no"
                                style={{ border: 0, top: 0, left: 0, width: '100%', height: '100%', position: 'absolute', overflow: 'hidden', borderRadius: '5px' }} allowFullScreen={1} allow="autoplay">
                            </iframe>
                        </figure>
                    </div>
                </div>

            </div>
            <div className="lg:w-2/5 mx-auto  text-center py-2"
                style={{ color: '#2A3158' }}
            >
                <h3 className='text-2xl font-medium py-5'>
                    Finally, a perfect match made in Tech Heaven!
                </h3>
            </div>
            <div className='mt-2 text-center mb-10'>
                <Link to='/pricing' target='_blank' className='text-white rounded-full px-4 py-2' style={{ background: '#df7324' }}>Get started</Link>
            </div>
        </div>
    )
}

export default HomeVideo
